import { lazy } from "react";
export const Home = lazy(() => import("../Pages/Home/Home"));
export const Login = lazy(() => import("../Pages/auth/Login/Login"));
export const Otp = lazy(() => import("../Pages/auth/Otp/Otp"));
export const Profile = lazy(() => import("../Pages/Profile/Profile"));
export const AddAgent = lazy(() => import("../Pages/AddAgent/AddAgent"));
export const AddNewSalaryGrade = lazy(() => import("../Pages/AddNewSalaryGrade/AddNewSalaryGrade"));
export const AgentsList = lazy(() =>
  import("./../Pages/AgentsList/AgentsList")
);
export const AllTemplates = lazy(() =>
  import("./../Pages/AllTemplates/AllTemplates")
);
export const AddTemplate = lazy(() =>
  import("./../Pages/AddTemplate/AddTemplate")
);
export const AddProject = lazy(() =>
  import("./../Pages/AddProject/AddProject")
);
export const JobDescriptions = lazy(() =>
  import("./../Pages/JobDescriptions/JobDescriptions")
);
export const Projects = lazy(() =>
  import("../Pages/ProjectsList/ProjectsList")
);
export const AllSalaryGrade = lazy(() =>
  import("../Pages/AllSalaryGrades/AllSalaryGrades")
);
export const SearchSalaryGrade = lazy(() =>
  import("../Pages/SearchSalaryGrade/SearchSalaryGrade")
);

export const AllJobDescription = lazy(() =>
  import("../Pages/AllJobDescriptions/AllJobDescriptions")
);
export const UserJobDescription = lazy(() =>
  import("../Pages/UserJobDescriptions/UserJobDescriptions")
);