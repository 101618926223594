import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lang: localStorage.getItem("lang") || "en",
  loader: false,
};

const MainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    loader: (state) => {
      state.loader = true;
    },
    changeLang: (state, action) => {
      localStorage.setItem("lang", action.payload);
      state.lang = action.payload;
    },
  },
});

export const MainReducer = MainSlice.reducer;
export const { loader, changeLang } = MainSlice.actions;
