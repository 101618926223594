import App from "./App";
import { CacheProvider, ThemeProvider } from "@emotion/react";
import { prefixer } from "stylis";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { createTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

function Theme() {
  const { lang } = useSelector((state) => state.main);

  const rtlCache = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });
  const [direction, setDirection] = useState(lang === "en" ? "ltr" : "rtl");

  useEffect(() => {
    setDirection(lang === "en" ? "ltr" : "rtl");
    document.body.dir = direction;
  }, [lang]);
  const ltrCache = createCache({
    key: "mui",
  });

  const theme = createTheme({
    direction: lang === "en" ? "ltr" : "rtl",
    palette: {
      primary: {
        main: `#003462`,
      },
      // secondary: {
      //   main: `rgba(${second[0]}, ${second[1]}, ${second[2]}, ${second[3]})`,
      // },
    },
    typography: {
      fontFamily: [
        "Montserrat-Regular",
        "Inter",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        // "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CacheProvider value={lang === "ar" ? rtlCache : ltrCache}>
        <App />
      </CacheProvider>
    </ThemeProvider>
  );
}

export default Theme;
