import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "../Navbar/Navbar";
import { isSuperAdmin, isUserHasPermission } from "../../Utils/Permissions";
import SideMenu from "../SideMenu/SideMenu";
// import SideMenu from "../SideMenu/SideMenu";

const ProtectedRoute = ({
  component: Component,
  permission,
  requiredRoles,
}) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  const permissionArr = permission
    ? permission.map((elt) => isUserHasPermission(elt))
    : [];


  if (permissionArr.some((elt) => elt === true) || isSuperAdmin()) {
    return (
      <div className="">
        {/* <div className="col-2">
        </div>
        */}

        <SideMenu component={Component} />
        {/* <Navbar />
        <Component /> */}
        {/* </div> */}
      </div>
    );
  } else {
    return (
      <>
        <Navigate to="/noPermissions" />;
      </>
    );
  }
};

export default ProtectedRoute;
