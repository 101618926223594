import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "..";
import toast from "react-hot-toast";
import messages from "../../assets/Local/Local";
import { Navigate } from "react-router-dom";

const initialState = {
  allProjects: [],
  allProjectsLookups: [],
  singleProject: null,
};

export const getJobDescriptionAlls = createAsyncThunk(
  "projects/allProjects",
  async () => {
    return await axiosInstance.get("Projects/GetAll");
  }
);

export const addProject = createAsyncThunk(
  "projects/addProject",
  async (data, { dispatch }) => {
    return await axiosInstance
      .post("Projects/AddProject", data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((error) => {
        toast.error(
          error.response.data.error.message
            ? error.response.data.error.message
            : "Something Wrong"
        );

        return error;
      });
  }
);

export const updateProject = createAsyncThunk(
  `projects/updateProject`,
  async (data, { dispatch }) => {
    return await axiosInstance
      .put(`Projects/UpdateProject`, data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);

export const getSingleProject = createAsyncThunk(
  `projects/getSingleProject`,
  async (id) => {
    return await axiosInstance
      .get(`Projects/${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);

const ProjectSlice = createSlice({
  name: "projects",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getJobDescriptionAlls.fulfilled, (state, action) => {
      state.loading = false;
      state.allProjects = action.payload.data.data;
      state.allProjectsLookups = action.payload.data.data.map((item) => ({
        id: item.id,
        name_en: item.projectName,
        name_ar: item.projectName,
      }));
    });
    builder.addCase(getSingleProject.fulfilled, (state, action) => {
      state.singleProject = action.payload.data;
    });
  },
});

export const ProjectReducer = ProjectSlice.reducer;
