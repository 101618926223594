import "./App.css";
import messages from "./assets/Local/Local";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Utils/Routes";
import { useDispatch, useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { changeLang } from "./Redux/slices/MainSlice";

function App() {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state.main);
  useEffect(() => {
    dispatch(
      changeLang(localStorage.getItem(lang) ? localStorage.getItem(lang) : "en")
    );
  }, []);

  return (
    <IntlProvider locale={lang} defaultLocale={lang} messages={messages[lang]}>
      <div
        className={lang === "ar" ? "rtl" : "ltr"}
        dir={lang === "ar" ? "rtl" : "ltr"}
      >
        <BrowserRouter>
          <Toaster position="bottom-center" reverseOrder={false} />
          {Routes}
        </BrowserRouter>
      </div>
    </IntlProvider>
  );
}

export default App;
