import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "..";
import toast from "react-hot-toast";
import messages from "../../assets/Local/Local";

const initialState = {
  allProjects: [],
  allProjectsLookups: [],
  jobDescriptionTemplate: {},
  jobDescriptionId: {},
  singleJobDescriptionData: null,
  geminiResponse: [],
  allJobDescriptions: [],
  userAllDescriptions: [],
  allTemplates: [],
  singleTemplate: null,
};

export const getSingleProject = createAsyncThunk(
  `jobDescription/singleProject`,
  async (id) => {
    return await axiosInstance.get(`Projects/${id}`);
  }
);
export const addJobDescription = createAsyncThunk(
  `jobDescription/addJobDescription`,
  async (data, { dispatch }) => {
    return await axiosInstance
      .post(`JobDescription/InsertJob`, data)
      .then((response) => {
        dispatch(getSingleJobDescriptionData(response.data.insertedID));
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);
export const updateJobDescription = createAsyncThunk(
  `jobDescription/updateJobDescription`,
  async (data, { dispatch }) => {
    return await axiosInstance
      .put(`JobDescription/UpdateJobDescription`, data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);
export const getAllJobsDescriptions = createAsyncThunk(
  `jobDescription/getAllJobsDescriptions`,
  async (data) => {
    return await axiosInstance.get(`JobDescription/GetAllJobDescription`, {
      params: data,
    });
  }
);
export const getAllUserJobDescription = createAsyncThunk(
  "jobDescription/getJDByUser",
  async (data) => {
    return await axiosInstance.get("JobDescription/GetJDByUser", {
      params: data,
    });
  }
);
export const getSingleJobDescriptionData = createAsyncThunk(
  `jobDescription/getSingleJobDescriptionData`,
  async (id) => {
    return await axiosInstance
      .get(`JobDescription/${id}`)
      .then((response) => {
        // toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);
export const updateJobDescriptionStatus = createAsyncThunk(
  `jobDescription/UpdateStatus`,
  async (data) => {
    return await axiosInstance
      .put(`JobDescription/UpdateStatus`, data)
      .then((response) => {
        toast.success(response.data.message);
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);

export const addNewTemplate = createAsyncThunk(
  `jobDescription/insertTemplate`,
  async (data, { dispatch }) => {
    return await axiosInstance
      .post(`JobDescription/InsertTemplate`, data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);

export const getAllTemplates = createAsyncThunk(
  `jobDescription/getAllTemplates`,
  async (data, { dispatch }) => {
    return await axiosInstance
      .get(`JobDescription/Template/GetAll`, data)
      .then((response) => {
        // toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);
export const updateNewTemplate = createAsyncThunk(
  `jobDescription/updateTemplate`,
  async (data, { dispatch }) => {
    return await axiosInstance
      .put(`JobDescription/UpdateTemplate`, data)
      .then((response) => {
        dispatch(getSingleTemplate(data.id));
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);

export const getSingleTemplate = createAsyncThunk(
  `jobDescription/getSingleTemplate`,
  async (id) => {
    return await axiosInstance
      .get(`JobDescription/Template/${id}`)
      .then((response) => {
        // toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);

const JobDescriptionSlice = createSlice({
  name: "jobDescription",
  initialState,
  reducers: {
    clear: (state) => {
      state.singleJobDescriptionData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSingleProject.fulfilled, (state, action) => {
      state.loading = false;
      state.jobDescriptionTemplate = action.payload.data;
    });

    builder.addCase(addJobDescription.fulfilled, (state, action) => {
      state.jobDescriptionId = action.payload.data.insertedID;
      localStorage.setItem("jobDescriptionId", action.payload.data.insertedID);
    });

    builder.addCase(getSingleJobDescriptionData.fulfilled, (state, action) => {
      state.geminiResponse = action.payload.data.jobDescriptions;
      state.singleJobDescriptionData = action.payload.data;
    });
    builder.addCase(getAllJobsDescriptions.fulfilled, (state, action) => {
      state.allJobDescriptions = action.payload.data;
    });
    builder.addCase(getAllUserJobDescription.fulfilled, (state, action) => {
      state.userAllDescriptions = action.payload.data;
    });
    builder.addCase(getAllTemplates.fulfilled, (state, action) => {
      state.allTemplates = action.payload.data.data;
    });
    builder.addCase(getSingleTemplate.fulfilled, (state, action) => {
      state.singleTemplate = action.payload.data;
    });
  },
});

export const JobDescriptionReducer = JobDescriptionSlice.reducer;
export const {     clear } = JobDescriptionSlice.actions;
