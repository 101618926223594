import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "..";
import toast from "react-hot-toast";
import messages from "../../assets/Local/Local";

const initialState = {
  allProjects: [],
  jobDescriptionTemplate: {},
};
export const getJobDescriptionAlls = createAsyncThunk(
  "jobDescription/allProjects",
  async () => {
    return await axiosInstance.get("Projects/GetAll");
  }
);
export const getSingleProject = createAsyncThunk(
  `jobDescription/singleProject`,
  async (id) => {
    return await axiosInstance.get(`Projects/${id}`);
  }
);
export const addJobDescription = createAsyncThunk(
  `jobDescription/Template`,
  async (data) => {
    return await axiosInstance
      .post(`JobDescription/InsertJob`, data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((error) => {
        console.log(error.response.data);
        
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);

const JobDescriptionSlice = createSlice({
  name: "jobDescription",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getJobDescriptionAlls.fulfilled, (state, action) => {
      state.loading = false;
      state.allProjects = action.payload.data.data.map((item) => ({
        id: item.id,
        name_en: item.projectName,
        name_ar: item.projectName,
      }));
    });
    builder.addCase(getSingleProject.fulfilled, (state, action) => {
      state.loading = false;
      state.jobDescriptionTemplate = action.payload.data;
    });
  },
});

export const JobDescriptionReducer = JobDescriptionSlice.reducer;
