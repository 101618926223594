import { configureStore } from "@reduxjs/toolkit";
import { MainReducer } from "./slices/MainSlice";
import { AuthReducer } from "./slices/AuthSlice";
import { LookupReducer } from "./slices/LookupsSlice";
import { SalaryGradeReducer } from "./slices/SalaryGradeSlice";
import { AgentsReducer } from "./slices/AgentSlice";
import { JobDescriptionReducer } from "./slices/JobDescriptionSlice";

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    main: MainReducer,
    lookups: LookupReducer,
    SalaryGrade: SalaryGradeReducer,
    agents: AgentsReducer,
    jobDescription: JobDescriptionReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
