const en = {
  en: {
    buttons: {
      save: "Save",
      cancel: "Cancel",
      search: "Search",
      new: "New",
      langBtn: "AR",
      send: "Send",
        view: "View",
      review:'Review'
    },
    shared: {
      login: "Sign in",
      done: "Saved Successfully",
      pending: "Pending",
      approved: "Approved",
      rejected: "Rejected",
    },
    controls: {
      password: "Password",
      email: "Email Address",
      from: "From",
      to: "To",
      firstName: "First Name",
      lastName: "Last Name",
      userName: "User Name",
      uploadFile: "Upload File",
      jobTitleNameEn: "Job Title (En)",
      jobTitleNameAr: "Job Title (Ar)",
      industry: "Industry",
      currency: "Currency",
      salaryMin: "Min",
      salaryMax: "Max",
      sector: "Sector",
      country: "Country",
      nationality: "Nationality",
      yearsOfExperience: "Years Of Experience",
      basicSalary: "Average Salary",
      companyName: "Company Name",
      source: "Source",
      status: "Status",
      jobTitle: "Job Title",
      employeeType: "Employee Type",
      project: "Project",
      languages: "Languages",
      neededData: "Needed Data",
      reportLine: "Report Line",
      createdBy: "Created By",
      approvalStatus: "Approval Status",
      projectName:'Project Name',
      companyNameEn: "Company Name (en)",
      companyNameAr: "Company Name (ar)",
      assignedTo: "Assigned To",
      toBeApprovedBy: "Approved By",
      template:'Template'
    },
    salaryGrades: {
      addNew: "Add New",
      manual: "Manual",
      aiSearching: "AI Searching",
      ai: "AI",
      cell: "Excel",
      actions: "Actions",
      addAgent: "Add Agent",
    },
    errors: {
      required: "This field is required",
      numberRequired: "This field must contain only numbers",
      wizardFileSizeErr: "Max size is 3MB",
      wizardFileTypeErr: "Excel files are only allowed",
      validEmail: "Invalid email",
    },
    uploads: {
      agentsTooltipRepeatEmails: "Emails cannot be repeated",
      agentsTooltipMandatory: "All columns are mandatory",
      agentsTooltipSalaryNameMaxChar: "salary name is maxed 50 characters each",
      agentsTooltipMaxMinDiff: "Max should be greater than min",
      agentsTooltipSalaryNameUnique: "salary grade name should be unique",
      agentsTooltipDownload: "Download the Excel sheet sample first",
      agentsTooltipRemoveColumn:
        "Don't remove or add columns to the excel sheet",
      agentsTooltipFirstLastNameMaxChar:
        "First name, Last name are maxed 50 characters each",
      uploadExcel: "Import from excel",
      downloadSample: "Download sample",
    },
    jobDescription: {
      jobDescriptionApproval: "Job Description Approval",
    },
  },
};

export default en;
