import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "..";
import toast from "react-hot-toast";
import messages from "../../assets/Local/Local";

const initialState = {
  agentsList: "",
  loading: "false",
};
export const addAgent = createAsyncThunk("agents/addAgent", async (data) => {
  return await axiosInstance
    .post("Auth/register", data)
    .then((response) => {
      toast.success(messages[localStorage.getItem("lang")].shared.done);
      return response;
    })
    .catch((err) => {
      toast.error(err.response.data.error.message);
      return err;
    });
});

export const getAgentsList = createAsyncThunk(
  "agents/getAgentsList",
  async (params) => {
    return await axiosInstance
      .get("Agent/list")
      .then((response) => {
        return response;
      })
      .catch((err) => {
        // toast.error(err.response.data.error.message, {});
        return err;
      });
  }
);

const AgentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAgentsList.fulfilled, (state, action) => {
      state.agentsList = action.payload.data.data;
    });
  },
});

export const AgentsReducer = AgentsSlice.reducer;
