import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import * as LazyComponent from "./LazyLoading";
import Loader from "../Components/Loader/Loader";
import ProtectedRoutes from "../Components/ProtectedRoutes/ProtectedRoutes";
// import history from "./History";

const Routess = (
  <>
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="login" element={<LazyComponent.Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoutes
              path="/"
              component={LazyComponent.Home}
              requiredRoles={["SUPER_ADMIN"]}
            />
          }
        />
        <Route
          path="salaryGrade"
          element={
            <ProtectedRoutes
              path="salaryGrade"
              component={LazyComponent.AddNewSalaryGrade}
              requiredRoles={["CONSULTANT", "SUPER_ADMIN"]}
            />
          }
        />
        <Route
          path="searchSalaryGrade"
          element={
            <ProtectedRoutes
              path="searchSalaryGrade"
              component={LazyComponent.SearchSalaryGrade}
              requiredRoles={["ENDUSER", "SUPER_ADMIN"]}
            />
          }
        />
        <Route
          path="allSalaryGrade"
          element={
            <ProtectedRoutes
              path="allSalaryGrade"
              component={LazyComponent.AllSalaryGrade}
              requiredRoles={["CONSULTANT", "SUPER_ADMIN"]}
            />
          }
        />
        <Route
          path="allTemplates"
          element={
            <ProtectedRoutes
              path="allTemplates"
              component={LazyComponent.AllTemplates}
              requiredRoles={["SUPER_ADMIN", "CONSULTANT"]}
            />
          }
        />
        <Route
          path="profile"
          element={
            <ProtectedRoutes
              path="profile"
              component={LazyComponent.Profile}
              requiredRoles={["CONSULTANT", "SUPER_ADMIN", "ENDUSER"]}
            />
          }
        />
        <Route
          path="addAgent"
          element={
            <ProtectedRoutes
              path="addAgent"
              component={LazyComponent.AddAgent}
              requiredRoles={["SUPER_ADMIN"]}
            />
          }
        />
        <Route
          path="jobdescriptions"
          element={
            <ProtectedRoutes
              path="jobdescriptions"
              component={LazyComponent.JobDescriptions}
              requiredRoles={["CONSULTANT", "SUPER_ADMIN", "ENDUSER"]}
            />
          }
        />
        <Route
          path="jobDescriptionApproval"
          element={
            <ProtectedRoutes
              path="jobDescriptionApproval"
              component={LazyComponent.AllJobDescription}
              requiredRoles={["CONSULTANT", "SUPER_ADMIN"]}
            />
          }
        />{" "}
        <Route
          path="userJobDescription"
          element={
            <ProtectedRoutes
              path="userJobDescription"
              component={LazyComponent.UserJobDescription}
              requiredRoles={["ENDUSER", "CONSULTANT", "SUPER_ADMIN"]}
            />
          }
        />
        <Route
          path="projects"
          element={
            <ProtectedRoutes
              path="projects"
              component={LazyComponent.Projects}
              requiredRoles={["CONSULTANT", "SUPER_ADMIN"]}
            />
          }
        />
        <Route
          path="addProject"
          element={
            <ProtectedRoutes
              path="addProject"
              component={LazyComponent.AddProject}
              requiredRoles={["CONSULTANT", "SUPER_ADMIN"]}
            />
          }
        />
        <Route
          path="editProject/:id"
          element={
            <ProtectedRoutes
              path="editProject/:id"
              component={LazyComponent.AddProject}
              requiredRoles={["CONSULTANT", "SUPER_ADMIN"]}
            />
          }
        />
        <Route
          path="agentList"
          element={
            <ProtectedRoutes
              path="agentList"
              component={LazyComponent.AgentsList}
              requiredRoles={["SUPER_ADMIN"]}
            />
          }
        />
        <Route
          path="addTemplate"
          element={
            <ProtectedRoutes
              path="addTemplate"
              component={LazyComponent.AddTemplate}
              requiredRoles={["SUPER_ADMIN", "CONSULTANT"]}
            />
          }
        />
        <Route
          path="editTemplate/:id"
          element={
            <ProtectedRoutes
              path="editTemplate/:id"
              component={LazyComponent.AddTemplate}
              requiredRoles={["SUPER_ADMIN", "CONSULTANT"]}
            />
          }
        />
        <Route path="login" element={<LazyComponent.Login />} />
        <Route path="otp" element={<LazyComponent.Otp />} />
      </Routes>
    </Suspense>
  </>
);

export default Routess;
