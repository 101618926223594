const ar = {
  ar: {
    buttons: {
      save: "حفظ",
      cancel: "الغاء",
      search: "بحث",
      new: "اضافة جديد",
      langBtn: "EN",
      send: "ارسال",
    },
    shared: {
      login: "تسجيل الدخول",

      done: "تم بنجاح",
      pending: "معلق",
      approved: "مقبول",
      rejected: "مرفوض",
    },
    controls: {
      password: "كلمة السر",
      email: "البريد الالكترونى",
      from: "من",
      to: "الى",
      firstName: "الاسم الاول",
      lastName: "الاسم الاخير",
      userName: "اسم المستخدم",
      jobTitleNameEn: "المسمى الوظيفي (بالانجليزية)",
      jobTitleNameAr: "المسمى الوظيفي (بالعربية)",
      industry: "المجال",
      sector: "القطاع",
      currency: "العملة",
      country: "البلد",
      nationality: "الجنسية",
      salaryMin: "الحد الادنى",
      salaryMax: "الحد الاقصى",
      yearsOfExperience: "سنوات الخبرة",
      basicSalary: "الراتب الاساسي",
      companyName: "اسم الشركة",
      source: "المصدر",
      status: "الحالة",
      jobTitle: "المسمى الوظيفي",
      employeeType:'نوع الموظف  '
    },
    salaryGrades: {
      addNew: "اضافة جديد",
      manual: "يدوي",
      aiSearching: "بحث AI",
      uploadFile: "ارفاق ملف",
      ai: "AI",
      cell: "Excel",
      addAgent: "اضافة مستخدم",

    },
    errors: {
      required: "هذا الحقل مطلوب",
      numberRequired: "هذا الحقل يجب ان يحتوي على ارقام فقط",
      wizardFileSizeErr: "الحجم المسموح 3 ميجا",
      wizardFileTypeErr: "Excel مسموح فقط بملفات",
      validEmail: "بريد الكترونى غير صحيح",
    },
    uploads: {
      agentsTooltipRepeatEmails: "لا يمكن تكرار البريد الإلكتروني",
      agentsTooltipMandatory: "جميع الأعمدة إلزامية",
      agentsTooltipSalaryNameMaxChar: "يبلغ الحد الأقصى لاسم الراتب 50 ​​حرفًا",
      agentsTooltipMaxMinDiff: "الحد الاقصى يجب ان يزيد عن الحد الادنى",
      agentsTooltipSalaryNameUnique: "لا يمكن تكرار اسم الراتب",
      agentsTooltipDownload: "قم بتنزيل نموذج الاكسل أولاً",
      agentsTooltipRemoveColumn: "لا تقم بإزالة أو إضافة أعمدة إلى الملف",
      agentsTooltipFirstLastNameMaxChar:
        "يبلغ الحد الأقصى للاسم الاول و الأخير 50 ​​حرفًا",
      uploadExcel: "رفع ملف",
      downloadSample: "تحميل نموذج",
    },
    jobDescription:{
      jobDescriptionApproval:'اعتماد الوصف الوظيفي'
    }
  },
};
export default ar;
