import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { isSuperAdmin, isUserHasPermission } from "../../Utils/Permissions";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logoCollapse from "./../../assets/Images/Icon-01.png";
import logo from "./../../assets/Images/Presentation-elements-06.png";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined";
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import CoPresentOutlinedIcon from "@mui/icons-material/CoPresentOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import ScreenSearchDesktopOutlinedIcon from "@mui/icons-material/ScreenSearchDesktopOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import RuleOutlinedIcon from "@mui/icons-material/RuleOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";

import "./SideMenu.css";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.white" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        MyCoolWebsite.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function DashboardContent(props) {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [selectedTab, setTab] = React.useState(0);
  const selectTab = (i) => {
    console.log(i);
    setTab(i);
  };
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    navigate("/login");
  };
  const { isAuthenticated, userName } = useSelector((state) => state.auth);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} className="bg-light shadow-sm">
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            ></Typography>

            <div className="menu">
              <div>
                <Button
                  id="basic-button"
                  aria-controls={navOpen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={navOpen ? "true" : undefined}
                  onClick={handleClick}
                  className="icon p-0"
                >
                  {userName
                    .split(" ")
                    .map((word) => word[0])
                    .join("")}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={navOpen}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleLogout();
                      handleClose();
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} className="sidemenu vh-100">
          <Toolbar
            sx={{
              display: "flex",
              // alignItems: "center",
              px: [1],
              // justifyContent: "center",
              height: "50px",
              overflow: "hidden",
              position: "fixed",
              top: 0,
              zIndex: 1000,
              left: "-115px",
            }}
            style={{ backgroundColor: "#114E7A" }}
          >
            {" "}
            <div className="col-4 ">
              <img
                src={open ? logo : logoCollapse}
                style={{ width: "80%" }}
                alt=""
              />
            </div>
            {/* <IconButton onClick={toggleDrawer} className="text-white bg-danger">
              <ChevronLeftIcon />
            </IconButton> */}
          </Toolbar>
          <Divider />
          <List
            component="nav"
            className="pt-5 mt-3 "
            style={{ backgroundColor: "#114E7A", height: "100vh" }}
          >
            {isAuthenticated && isSuperAdmin() && (
              <NavLink className="nav-link mt-4 " to="/" >
                <p className="text-white text-opacity-75 fw-medium ms-3 mb-2">
                  <DonutSmallOutlinedIcon className="me-2 fs-5 my-2" />
                  Dashboard
                </p>
              </NavLink>
            )}
            {isAuthenticated &&
              (isSuperAdmin() ||
                isUserHasPermission("Deals") ||
                isUserHasPermission("Approve_Proposal_Request") ||
                isUserHasPermission("Upload_Proposal_Request") ||
                isUserHasPermission("Send_Bidding_Request") ||
                isUserHasPermission("Send_Client_Proposal_Request")) && (
                <div>
                  {/* proposal */}
                  <Accordion
                     
                    className="shadow-none"
                    style={{ backgroundColor: "#114E7A" }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon className="text-white text-opacity-75" />
                      }
                      aria-controls="panel5-content"
                      id="panel5-header"
                      className="shadow-none mb-2"
                    >
                      <p className="text-white text-opacity-75 fw-medium my-3">
                        <CoPresentOutlinedIcon className="fs-5 me-2" />{" "}
                        Proposals
                      </p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        {(isSuperAdmin() || isUserHasPermission("Deals")) && (
                          <>
                            <NavLink className="nav-link mx-3 my-2" to="/deals">
                              <CurrencyExchangeOutlinedIcon className="fs-6 me-2" />{" "}
                              <small> Deals</small>
                            </NavLink>
                          </>
                        )}
                        {(isSuperAdmin() ||
                          isUserHasPermission("Upload_Proposal_Request")) && (
                          <NavLink
                            className="nav-link mx-3 my-2"
                            to={{
                              pathname: "/assignedproposals",
                              search: "logged",
                            }}
                          >
                            <FormatListBulletedOutlinedIcon className="fs-5 me-2" />{" "}
                            <small> Sent Proposals</small>
                          </NavLink>
                        )}
                        {(isSuperAdmin() ||
                          isUserHasPermission(
                            "Send_Client_Proposal_Request"
                          )) && (
                          <NavLink
                            className="nav-link mx-3 my-2"
                            to={{
                              pathname: "/proposallist",
                              search: "assigned",
                            }}
                          >
                            <SummarizeOutlinedIcon className="fs-5 me-2" />{" "}
                            <small> Proposals Requests</small>
                          </NavLink>
                        )}

                        {(isSuperAdmin() ||
                          isUserHasPermission("Approve_Proposal_Request")) && (
                          <NavLink
                            className="nav-link mx-3 my-2"
                            to={{
                              pathname: "/approvalproposals",
                              search: "approval",
                            }}
                          >
                            <RuleOutlinedIcon className="fs-5 me-2" />{" "}
                            <small> Approval Proposals</small>
                          </NavLink>
                        )}
                      </>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}{" "}
            {isAuthenticated &&
              (isSuperAdmin() ||
                isUserHasPermission("Send_Bidding_Request") ||
                isUserHasPermission("Send_Client_Proposal_Request")) && (
                <div>
                  {/* biddin */}
                  <Accordion
                     
                    className="shadow-none"
                    style={{ backgroundColor: "#114E7A" }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon className="text-white text-opacity-75" />
                      }
                      aria-controls="panel2-content"
                      id="panel2-header"
                      className="shadow-none mb-1"
                    >
                      <p className="text-white text-opacity-75 fw-medium my-3">
                        <GavelOutlinedIcon className="me-2 fs-5" />
                        Biddings
                      </p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        {(isSuperAdmin() ||
                          isUserHasPermission("Send_Bidding_Request")) && (
                          <NavLink
                            className="nav-link mx-3 my-2"
                            to={{
                              pathname: "/biddinglist",
                              search: "sent",
                            }}
                          >
                            <PlaylistAddOutlinedIcon className="me-2  fs-5 " />{" "}
                            <small> New</small>
                          </NavLink>
                        )}
                        {(isSuperAdmin() ||
                          isUserHasPermission("Approve_Bidding_Request")) && (
                          <NavLink
                            className="nav-link mx-3 my-2"
                            to={{
                              pathname: "/approvpeddings",
                              search: "approval",
                            }}
                          >
                            <RuleOutlinedIcon className="fs-5 me-2" />{" "}
                            <small> Approve Bidding</small>
                          </NavLink>
                        )}
                      </>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
            <div    style={{ backgroundColor: "#114E7A" }}>
              {" "}
              {(isSuperAdmin() || isUserHasPermission("Add_New_Projects")) && (
                <NavLink
                  className="nav-link text-white text-opacity-75 fw-medium mx-3 py-3 "
                  to="/projects"
                >
                  <AccountTreeOutlinedIcon className="me-2 fs-5 " />
                  <> Projects</>
                </NavLink>
              )}
            </div>
          
            {/* SalaryGrade */}
            {isAuthenticated &&
              (isSuperAdmin() ||
                isUserHasPermission("All_Salary_Grade") ||
                isUserHasPermission("Search_Salary_Grade") ||
                isUserHasPermission("Accept_Reject_Salary_Grade") ||
                isUserHasPermission("Add_NEW_Salary_Grade")) && (
                <Accordion
                   
                  className="shadow-none"
                  style={{ backgroundColor: "#114E7A" }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon className="text-white text-opacity-75" />
                    }
                    aria-controls="panel3-content"
                    id="panel3-header"
                    className="shadow-none mb-3"
                  >
                    <p className="text-white text-opacity-75 fw-medium my-3">
                      <AutoGraphOutlinedIcon className=" me-2 fs-4 " />
                      Salary Grade
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    {(isSuperAdmin() ||
                      isUserHasPermission("Add_NEW_Salary_Grade")) && (
                      <NavLink
                        className="nav-link mx-3 my-2"
                        aria-current="page"
                        to="/salaryGrade"
                      >
                        <PlaylistAddOutlinedIcon className="me-2  fs-5 " />{" "}
                        <small> New</small>
                      </NavLink>
                    )}
                    {(isSuperAdmin() ||
                      isUserHasPermission("Accept_Reject_Salary_Grade") ||
                      isUserHasPermission("All_Salary_Grade")) && (
                      <>
                        <NavLink
                          className="nav-link mx-3 my-2"
                          aria-current="page"
                          to="/allSalaryGrade"
                        >
                          <RuleOutlinedIcon className="fs-5 me-2" />{" "}
                          <small> Approve</small>
                        </NavLink>
                      </>
                    )}
                    {isAuthenticated &&
                      (isSuperAdmin() ||
                        isUserHasPermission("Search_Salary_Grade")) && (
                        <NavLink
                          className="nav-link mx-3 my-2"
                          to="/searchSalaryGrade"
                        >
                          <QueryStatsOutlinedIcon className="me-2  fs-5 " />{" "}
                          <small> Search</small>
                        </NavLink>
                      )}
                  </AccordionDetails>
                </Accordion>
              )}
                {/* Job Desc */}
            {isAuthenticated &&
              (isSuperAdmin() ||
                isUserHasPermission("Add_Job_Description") ||
                isUserHasPermission("Approve_Job_Description") ||
                isUserHasPermission("Template_List") ||
                isUserHasPermission("Add_Template")) && (
                <>
                  <Accordion
                     
                    className="shadow-none"
                    style={{ backgroundColor: "#114E7A" }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon className="text-white text-opacity-75" />
                      }
                      aria-controls="panel6-content"
                      id="panel6-header"
                      className="shadow-none mb-3 "
                    >
                      <p className="text-white text-opacity-75 fw-medium my-3">
                        <ScreenSearchDesktopOutlinedIcon className="me-2 fs-4 " />
                        Job Descriptions
                      </p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        {(isSuperAdmin() ||
                          isUserHasPermission("Add_Job_Description")) && (
                          <>
                            <NavLink
                              className="nav-link mx-3 my-2"
                              to={"jobdescriptions"}
                              onClick={() => {
                                navigate("/jobdescriptions");
                                window.location.reload();
                              }}
                            >
                              <PlaylistAddOutlinedIcon className="me-2  fs-5 " />{" "}
                              <small> New</small>
                            </NavLink>
                            <NavLink
                              className="nav-link mx-3 my-2"
                              to="/userJobDescription"
                            >
                              <SummarizeOutlinedIcon className="fs-5 me-2" />{" "}
                              <small> Requests</small>
                            </NavLink>
                          </>
                        )}
                      </>
                      <>
                        {(isSuperAdmin() ||
                          isUserHasPermission("Approve_Job_Description")) && (
                          <NavLink
                            className="nav-link mx-3 my-2"
                            to="/jobDescriptionApproval"
                          >
                            <RuleOutlinedIcon className="fs-5 me-2" />{" "}
                            <small> Approve</small>
                          </NavLink>
                        )}

                        {(isSuperAdmin() ||
                          isUserHasPermission("Add_Template")) && (
                          <NavLink
                            className="nav-link mx-3 my-2"
                            to="/allTemplates"
                          >
                            <ViewQuiltOutlinedIcon className="me-2  fs-5" />{" "}
                            <small> Templates</small>
                          </NavLink>
                        )}
                      </>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
            {isAuthenticated &&
              (isSuperAdmin() || isUserHasPermission("Agents_List")) && (
                <Accordion
                   
                  className="shadow-none"
                  style={{ backgroundColor: "#114E7A" }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon className="text-white text-opacity-75" />
                    }
                    aria-controls="panel2-content"
                    id="panel2-header"
                    className="shadow-none "
                  >
                    <p className="text-white text-opacity-75 fw-medium my-0">
                      <AdminPanelSettingsOutlinedIcon className="me-1 fs-4" />{" "}
                      Administration
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    {(isSuperAdmin() || isUserHasPermission("Agents_List")) && (
                      <NavLink
                        className="nav-link mx-3 my-2"
                        aria-current="page"
                        to="/agentList"
                      >
                        <PeopleAltOutlinedIcon className="me-2  fs-5" />{" "}
                        <small> Agents</small>
                      </NavLink>
                    )}
                    {isAuthenticated && isSuperAdmin() && (
                      <NavLink
                        className="nav-link mx-3 my-2"
                        aria-current="page"
                        to="/roleList"
                      >
                        <LockPersonOutlinedIcon className="me-2  fs-5" />{" "}
                        <small> Roles</small>
                      </NavLink>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.white
                : theme.palette.white,
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <div className="mt-3 mx-3">{<props.component />}</div>
          {/* <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid>
              <Grid item xs={12} md={12} lg={12}>
                
              </Grid>
            </Grid>
            {/* <Copyright sx={{ pt: 4 }} /> */}
          {/* </Container> */}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function SideMenu(props) {
  return <DashboardContent component={props.component} />;
}
