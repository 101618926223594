import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "./../index";

const initialState = {
  nationalities: [],
  industries: [],
  countries: [],
  currency: [],
  sector: [],
  sectorAndIndustries: [],
  yearsOfExperiences: [],
};

export const getNationalities = createAsyncThunk(
  "lookups/getNationalities",
  async () => {
    return await axiosInstance.get("lookup/nationalities");
  }
);
export const getIndustries = createAsyncThunk(
  "lookups/getIndustries",
  async () => {
    return await axiosInstance.get("lookup/Industries");
  }
);
export const getCountries = createAsyncThunk(
  "lookups/getCountries",
  async () => {
    return await axiosInstance.get("lookup/Countries");
  }
);
export const getCurrency = createAsyncThunk("lookups/getCurrency", async () => {
  return await axiosInstance.get("lookup/Currency");
});
export const getSector = createAsyncThunk("lookups/getSector", async () => {
  return await axiosInstance.get("lookup/Sector");
});
export const getSectorAndIndustries = createAsyncThunk(
  "lookups/getSectorAndIndustries",
  async () => {
    return await axiosInstance.get("lookup/SectorAndIndustries");
  }
);
export const getyearsOfExperiences = createAsyncThunk(
  "lookups/getyearsOfExperiences",
  async () => {
    return await axiosInstance.get("lookup/yearsOfExperiences");
  }
);

const LookupSlice = createSlice({
  name: "lookups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNationalities.fulfilled, (state, action) => {
      state.nationalities = action.payload.data;
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload.data;
    });
    builder.addCase(getCurrency.fulfilled, (state, action) => {
      state.currency = action.payload.data;
    });
    builder.addCase(getIndustries.fulfilled, (state, action) => {
      state.industries = action.payload.data;
    });
    builder.addCase(getSectorAndIndustries.fulfilled, (state, action) => {
      state.sectorAndIndustries = action.payload.data;
    });
    builder.addCase(getSector.fulfilled, (state, action) => {
      state.sector = action.payload.data;
    });
    builder.addCase(getyearsOfExperiences.fulfilled, (state, action) => {
      state.yearsOfExperiences = action.payload.data;
    });
  },
});
export const LookupReducer = LookupSlice.reducer;
