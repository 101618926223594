import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import * as LazyComponent from "./LazyLoading";
import Loader from "../Components/Loader/Loader";
import ProtectedRoutes from "../Components/ProtectedRoutes/ProtectedRoutes";

const Routess = (
  <>
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="login" element={<LazyComponent.Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoutes
              path="/"
              component={LazyComponent.Home}
              requiredRoles={["SUPER_ADMIN"]}
            />
          }
        />
        <Route
          path="ai"
          element={
            <ProtectedRoutes
              path="ai"
              component={LazyComponent.NewSalary}
              requiredRoles={["CONSULTANT", "SUPER_ADMIN"]}
            />
          }
        />

        <Route
          path="searchSalaryGrade"
          element={
            <ProtectedRoutes
              path="searchSalaryGrade"
              component={LazyComponent.SearchSalaryGrade}
              requiredRoles={["ENDUSER", "SUPER_ADMIN"]}
            />
          }
        />

        <Route
          path="allSalaryGrade"
          element={
            <ProtectedRoutes
              path="allSalaryGrade"
              component={LazyComponent.AllSalaryGrade}
              requiredRoles={["CONSULTANT", "SUPER_ADMIN"]}
            />
          }
        />

        <Route
          path="profile"
          element={
            <ProtectedRoutes
              path="profile"
              component={LazyComponent.Profile}
              requiredRoles={["CONSULTANT", "SUPER_ADMIN", "ENDUSER"]}
            />
          }
        />
        <Route
          path="addAgent"
          element={
            <ProtectedRoutes
              path="addAgent"
              component={LazyComponent.AddAgent}
              requiredRoles={["SUPER_ADMIN"]}
            />
          }
        />

        <Route
          path="jobdescriptions"
          element={
            <ProtectedRoutes
              path="jobdescriptions"
              component={LazyComponent.JobDescriptions}
              requiredRoles={["TESTER"]}
            />
          }
        />
        <Route
          path="agentList"
          element={
            <ProtectedRoutes
              path="agentList"
              component={LazyComponent.AgentsList}
              requiredRoles={["SUPER_ADMIN"]}
            />
          }
        />
        <Route path="login" element={<LazyComponent.Login />} />
        <Route path="otp" element={<LazyComponent.Otp />} />
      </Routes>
    </Suspense>
  </>
);

export default Routess;
