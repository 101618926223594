import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "./../index";
import toast from "react-hot-toast";
import messages from "../../assets/Local/Local";

const initialState = {
  userToken: localStorage.getItem("token") || null,
  error: null,
  isLoading: false,
  isAuthenticated: localStorage.getItem("isAuthenticated") || null,
  userName: localStorage.getItem("userName"),
  userRole: "",
};

export const handleLogin = createAsyncThunk("auth/login", async (data) => {
  return await axiosInstance
    .post("Auth/login", data)
    .then((response) => response)
    .catch((err) => err);
});

export const confirmLoginOtp = createAsyncThunk(
  "auth/confirmLoginOtp",
  async (data) => {
    return await axiosInstance
      .post("Auth/ConfirmLoginOtp", data)
      .then((response) => response)
      .catch((err) => err);
  }
);

export const resendOtpCode = createAsyncThunk(
  "auth/resendOtpCode",
  async (data) => {
    return await axiosInstance
      .post("Auth/ResendLoginOTP", data)
      .then((response) => response)
      .catch((err) => err);
  }
);

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.userToken = null;
      localStorage.removeItem("token");
      state.isAuthenticated = false;
      localStorage.removeItem("isAuthenticated");
    },
  },
  extraReducers: (builder) => {
    // Login

    builder.addCase(handleLogin.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(handleLogin.fulfilled, (state, action) => {
      if (
        action?.payload?.status !== 200
        // action.payload?.response?.status !== 200
      ) {
        state.error = action.payload.response?.data.error.message;
      }
      if (action.payload.status === 200) {
        state.error = null;

        state.userName = action.payload.data.data.userName;
        state.userToken = action.payload.data.data.token;
        localStorage.setItem("token", action.payload.data.data.token);
        localStorage.setItem("userName", action.payload.data.data.userName);
        localStorage.setItem(
          "isSuperAdmin",
          action.payload.data.data.isSuperAdmin
        );
        localStorage.setItem(
          "employeeType",
          action.payload.data.data.employeeType
        );

        let x = ["SUPER_ADMIN", "CONSULTANT", "ENDUSER"];

        if (action.payload.data.data.isSuperAdmin) {
          state.userRole = "SUPER_ADMIN";
        } else if (action.payload.data.data.employeeType === 2) {
          state.userRole = "CONSULTANT";
        } else if (action.payload.data.data.employeeType === 3) {
          state.userRole = "TESTER";
        } else {
          state.userRole = "ENDUSER";
        }
        localStorage.setItem("role", state.userRole);
      }

      state.isLoading = false;
    });
    builder.addCase(handleLogin.rejected, (state, action) => {});

    // Confirm OTP
    builder.addCase(confirmLoginOtp.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data.error.message;
    });
    builder.addCase(confirmLoginOtp.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(confirmLoginOtp.fulfilled, (state, action) => {
      if (action.payload.status !== 200) {
        state.error = "invalid OTP";
      }
      // if (action.payload?.response?.status !== 200) {
      //   state.error = "invalid OTP";
      // }
      if (action.payload.status === 200) {
        state.isAuthenticated = true;
        localStorage.setItem("token", action.payload?.data.data.token);
        localStorage.setItem("isAuthenticated", true);
      }
      state.isLoading = false;
    });

    //Resend OTP
    builder.addCase(resendOtpCode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data.error.message;
    });
    builder.addCase(resendOtpCode.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(resendOtpCode.fulfilled, (state, action) => {
      // state.userToken = action.payload.data.data.token;
      // localStorage.setItem("token", action.payload.data.data.token);
      state.error = null;
      if (action.payload.status !== 200) {
        state.error = action.payload.response?.data.error.message;
      }
      state.isLoading = false;
    });
  },
});

export const AuthReducer = AuthSlice.reducer;
export const { logout } = AuthSlice.actions;
