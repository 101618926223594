import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "..";
import toast from "react-hot-toast";
import messages from "../../assets/Local/Local";

const initialState = {
  allBiddings: [],
  allPermissions: null,
  singleBidding: null,
};

export const getAllBiddings = createAsyncThunk(
  "bidding/getAllBiddings",
  async (searchData) => {
    return await axiosInstance
      .get("BiddingRequests/GetAll", { params: searchData })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);
export const addBidding = createAsyncThunk(
  "bidding/addBidding",
  async (data, { dispatch }) => {
    return await axiosInstance
      .post("BiddingRequests/Insert", data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((error) => {
        toast.error(
          error.response.data.error.message
            ? error.response.data.error.message
            : "Something Wrong"
        );

        return error;
      });
  }
);

export const getSingleBidding = createAsyncThunk(
  `bidding/getSingleBidding`,
  async (id) => {
    return await axiosInstance
      .get(`BiddingRequests/${id}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        toast.error(error.response.data.error.message);
        return error;
      });
  }
);

export const updateBidding = createAsyncThunk(
  "bidding/update",
  async (data, { dispatch }) => {
    return await axiosInstance
      .put(`BiddingRequests/Update/${data.id}`, data.values)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
);

export const updateBiddingStatus = createAsyncThunk(
  "bidding/updateStatus",
  async (data, { dispatch }) => {
    return await axiosInstance
      .post(`BiddingRequests/UpdateBiddingStatus`, data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
);
const BiddingsSlice = createSlice({
  name: "bidding",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllBiddings.fulfilled, (state, action) => {
      state.allBiddings = action.payload.data.data;
    });
    builder.addCase(getSingleBidding.fulfilled, (state, action) => {
      state.singleBidding = action.payload.data.data;
    });
  },
});

export const BiddingReducer = BiddingsSlice.reducer;
