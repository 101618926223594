import React from "react";
import { useNavigate } from "react-router-dom";
import "./NoPermissions.css";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import Navbar from "../../Components/Navbar/Navbar";
import SideMenu from "../../Components/SideMenu/SideMenu";

function NoPermissions(props) {
  const dispatch = useDispatch();
  const messages = props.intl.messages;
  const navigate = useNavigate();
  const { lang } = useSelector((state) => state.main);

  return (
      <div className="container text-center ">
        <h3 className="my-4 mt-5">No Permission</h3>
        <p>Please login with another user</p>
      </div>
  );
}

export default injectIntl(NoPermissions);

//
