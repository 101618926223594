import { lazy } from "react";

export const Home = lazy(() => import("../Pages/Home/Home"));
export const Login = lazy(() => import("../Pages/auth/Login/Login"));
export const Otp = lazy(() => import("../Pages/auth/Otp/Otp"));
export const Profile = lazy(() => import("../Pages/Profile/Profile"));
export const AddAgent = lazy(() => import("../Pages/AddAgent/AddAgent"));
export const NewSalary = lazy(() => import("../Pages/AddNew/AddNew"));
export const AgentsList = lazy(() =>
  import("./../Pages/AgentsList/AgentsList")
);
export const JobDescriptions = lazy(() =>
  import("./../Pages/JobDescriptions/JobDescriptions")
);

export const AllSalaryGrade = lazy(() =>
  import("../Pages/AllSalaryGrades/AllSalaryGrades")
);
export const SearchSalaryGrade = lazy(() =>
  import("../Pages/SearchSalaryGrade/SearchSalaryGrade")
);
