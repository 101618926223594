import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "..";
import messages from "../../assets/Local/Local";
import toast from "react-hot-toast";

const initialState = {
  excelFileURL: "",
  searchData: [],
  allGrades: [],
  loading: "false",
  analyze: null,
  statistics: null,
};

export const addSalaryGrade = createAsyncThunk(
  "salaryGrade/addSalaryGrade",
  async (data) => {
    return await axiosInstance
      .post("SalaryGrade/AddSalaryGrade", data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
        return err;
      });
  }
);

export const addSalaryGradeFromAI = createAsyncThunk(
  "salaryGrade/AddSalaryGradeFromAI",
  async (data) => {
    return await axiosInstance
      .post("SalaryGrade/AddSalaryGradeFromAI", data)
      .then((response) => {
        toast.success(messages[localStorage.getItem("lang")].shared.done);
        return response;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
        return err;
      });
  }
);

export const getGradeExcelFile = createAsyncThunk(
  "salaryGrade/getGradeExcelFile",
  async () => {
    return await axiosInstance
      .get("SalaryGrade/Get_SalaryGradeSampleExcelUrl")
      .then((response) => {
        return response;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message, {});

        return err;
      });
  }
);
export const uploadGradeExcelFile = createAsyncThunk(
  "salaryGrade/uploadGradeExcelFile",
  async (data) => {
    return await axiosInstance
      .post("SalaryGrade/Upload_ApplicantExcel", data)
      .then((response) => {
        toast.success(response.data.message);
        return response;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message, {});

        return err;
      });
  }
);
export const SearchSalaryGrades = createAsyncThunk(
  "salaryGrade/searchSalaryGrades",
  async (params) => {
    return await axiosInstance
      .get("SalaryGrade/Get/Approved", {
        params,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        // toast.error(err.response.data.error.message, {});

        return err;
      });
  }
);
export const getAllSalaryGrades = createAsyncThunk(
  "salaryGrade/allSalaryGrades",
  async (params) => {
    return await axiosInstance
      .get("SalaryGrade/Get/Pending", {
        params,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        // toast.error(err.response.data.error.message, {});
        return err;
      });
  }
);

export const updateGradeStatus = createAsyncThunk(
  "salaryGrade/UpdateStatus",
  async ({ data, filteredData }, { dispatch }) => {
    try {
      let res = await axiosInstance.put("SalaryGrade/UpdateStatus", {
        ...data,
      });
      dispatch(getAllSalaryGrades({ ...filteredData }));
      return res;
    } catch (error) {
      toast.error("Error !");
      return error;
    }
  }
);
export const getStatistics = createAsyncThunk(
  "salaryGrade/statistics",
  async (params) => {
    return await axiosInstance
      .get("SalaryGrade/statistics", {
        params,
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  }
);

const SalaryGradeSlice = createSlice({
  name: "salaryGrade",
  initialState,
  reducers: {
    clearData: (state) => {
      state.loading = true;
      state.searchData = [];
    },
  },
  extraReducers: (builder) => {
    // add new salary grade
    builder.addCase(addSalaryGrade.fulfilled, (state, action) => {});

    //
    builder.addCase(getGradeExcelFile.fulfilled, (state, action) => {
      state.excelFileURL = action.payload.data.data;
    });

    //all grades
    builder.addCase(getAllSalaryGrades.fulfilled, (state, action) => {
      state.allGrades = action.payload.data.data;
    });

    builder.addCase(SearchSalaryGrades.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(SearchSalaryGrades.fulfilled, (state, action) => {
      state.loading = false;
      state.searchData = action.payload.data.data.salaryGradesList;
      state.analyze = { ...action.payload.data.data };
    });

    builder.addCase(updateGradeStatus.fulfilled, (state, action) => {
      toast.success(action.payload.data.message);
    });

    builder.addCase(getStatistics.fulfilled, (state, action) => {
      state.statistics = action.payload.data.data;
    });
  },
});

export const SalaryGradeReducer = SalaryGradeSlice.reducer;
export const { clearData } = SalaryGradeSlice.actions;
