// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav .icon {
  width: 45px;
  height: 45px;
  background-color: #003462;
  text-decoration: none;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1.1rem !important;
  letter-spacing: 1px;
  margin-inline-start: 10px;
}
nav .nav-link {
  color: gray;
}
.navbar-nav .nav-link.active {
  color: #003462;
  border-bottom: 3px solid #003462;
}
nav .dropdown-center .dropdown-toggle::after{
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,4BAA4B;EAC5B,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,WAAW;AACb;AACA;EACE,cAAc;EACd,gCAAgC;AAClC;AACA;EACE,aAAa;AACf","sourcesContent":["nav .icon {\n  width: 45px;\n  height: 45px;\n  background-color: #003462;\n  text-decoration: none;\n  color: white;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-weight: 500;\n  font-size: 1.1rem !important;\n  letter-spacing: 1px;\n  margin-inline-start: 10px;\n}\nnav .nav-link {\n  color: gray;\n}\n.navbar-nav .nav-link.active {\n  color: #003462;\n  border-bottom: 3px solid #003462;\n}\nnav .dropdown-center .dropdown-toggle::after{\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
