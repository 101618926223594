import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  allDeals: [],
  pipelines: [],
  allPersonsLookups: [],
  filters: [],
  organizationsLookups: [],
};
const pipeDriveApi = axios.create({
  baseURL: process.env.REACT_APP_PIPEDRIVE_BASE_URL,
  params: {
    api_token: process.env.REACT_APP_PIPEDRIVE_API_KEY,
    // api_token: "7bfd922327927da4dc5f294ea0ab92bc45880291",
  },
});

export const getDeals = createAsyncThunk(
  "deals/getDeals",
  async (filteredData) => {
    const response = await pipeDriveApi.get("/deals", { params: filteredData });

    return response.data;
  }
);
export const getFilters = createAsyncThunk("deals/filters", async () => {
  const response = await pipeDriveApi.get("/filters", {
    params: {
      type: "deals",
    },
  });

  return response.data;
});

export const getFilteredDeals = createAsyncThunk(
  "deals/getFilteredDeals",
  async (searchData) => {
    const response = await pipeDriveApi.get("/deals/search", {
      params: searchData,
    });

    return response.data;
  }
);

const DealsSlice = createSlice({
  name: "deals",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDeals.fulfilled, (state, action) => {
      state.allDeals = action.payload.data ? action.payload.data : [];
      if (action.payload.related_objects) {
        state.pipelines = action.payload.related_objects?.pipeline;
        state.allPersonsLookups = Object.values(
          action.payload?.related_objects?.user
        ).map((ele) => {
          return { id: ele.id, name_en: ele.name, name_ar: ele.name };
        });
        state.organizationsLookups = Object.values(
          action.payload.related_objects.organization
        ).map((ele) => {
          return { id: ele.id, name_en: ele.name, name_ar: ele.name };
        });
      }
    });
    builder.addCase(getFilteredDeals.fulfilled, (state, action) => {
      state.allDeals = action.payload.data.items;
    });
    builder.addCase(getFilters.fulfilled, (state, action) => {
      state.filters = action.payload.data.map((ele) => {
        return { id: ele.id, name_en: ele.name, name_ar: ele.name };
      });
    });
  },
});
export const DealsReducer = DealsSlice.reducer;
