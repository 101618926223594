import { configureStore } from "@reduxjs/toolkit";
import { MainReducer } from "./slices/MainSlice";
import { AuthReducer } from "./slices/AuthSlice";
import { LookupReducer } from "./slices/LookupsSlice";
import { SalaryGradeReducer } from "./slices/SalaryGradeSlice";
import { AgentsReducer } from "./slices/AgentSlice";
import { JobDescriptionReducer } from "./slices/JobDescriptionSlice";
import { ProjectReducer } from "./slices/ProjectSlice";
import { RolesReducer } from "./slices/RolesSlice";
import { ProposalReducer } from "./slices/ProposalSlice";
import { BiddingReducer } from "./slices/BiddingSlice";

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    main: MainReducer,
    lookups: LookupReducer,
    SalaryGrade: SalaryGradeReducer,
    agents: AgentsReducer,
    jobDescription: JobDescriptionReducer,
    projects: ProjectReducer,
    roles: RolesReducer,
    proposals: ProposalReducer,
    bidding: BiddingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
