import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./Navbar.css";
import { Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { changeLang } from "../../Redux/slices/MainSlice";
import logo from "./../../assets/Images/image.png";
import { injectIntl } from "react-intl";
import { isSuperAdmin, isUserHasPermission } from "../../Utils/Permissions";

function Navbar(props) {
  const dispatch = useDispatch();
  const messages = props.intl.messages;
  const navigate = useNavigate();
  const { lang } = useSelector((state) => state.main);
  const { isAuthenticated, userName } = useSelector((state) => state.auth);
  function switchLang() {
    dispatch(changeLang(lang === "ar" ? "en" : "ar"));
  }

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    navigate("/login");
  };

  return (
    <Paper>
      <nav className="navbar navbar-expand-lg fixed-top bg-light">
        <div className="container ">
          <Link className="navbar-brand col-2" to="/">
            <img src={logo} alt="logo" className="w-75" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse col-9 "
            id="navbarSupportedContent"
          >
            <ul
              className={` ${
                lang === "en" ? "ms-auto" : "me-auto"
              } navbar-nav align-items-center  mb-2 mb-lg-0`}
            >
              <>
                {isAuthenticated && isSuperAdmin() && (
                  <li className="nav-item mx-2 mx-1">
                    <NavLink
                      className="nav-link fw-semibold "
                      aria-current="page"
                      to="/"
                    >
                      Dashboard
                    </NavLink>
                  </li>
                )}
                {(isSuperAdmin() ||
                  isUserHasPermission("Add_Job_Description") ||
                  isUserHasPermission("Approve_Job_Description") ||
                  isUserHasPermission("Template_List") ||
                  isUserHasPermission("Add_Template")) && (
                  <>
                    <div className="dropdown mx-2">
                      <div
                        className="nav-link fw-semibold dropdown-toggle "
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ cursor: "pointer" }}
                      >
                        Job Description
                      </div>
                      <ul className="dropdown-menu">
                        <>
                          {(isSuperAdmin() ||
                            isUserHasPermission("Add_Job_Description")) && (
                            <>
                              <li className="nav-item  mx-1">
                                <Link
                                  className="nav-link fw-semibold text-black"
                                  aria-current="page"
                                  onClick={() => {
                                    navigate("/jobdescriptions");
                                    window.location.reload();
                                  }}
                                >
                                  New JD
                                </Link>
                              </li>

                              <li className="nav-item mx-1">
                                <Link
                                  className="nav-link fw-semibold text-black"
                                  aria-current="page"
                                  to="/userJobDescription"
                                >
                                  JD Requests
                                </Link>
                              </li>
                            </>
                          )}
                        </>
                        <>
                          {(isSuperAdmin() ||
                            isUserHasPermission("Add_New_Projects")) && (
                            <li className="nav-item mx-1">
                              <Link
                                className="nav-link fw-semibold text-black"
                                to="/projects"
                              >
                                Projects
                              </Link>
                            </li>
                          )}
                          {(isSuperAdmin() ||
                            isUserHasPermission("Approve_Job_Description")) && (
                            <li className="nav-item mx-1">
                              <Link
                                className="nav-link fw-semibold text-black"
                                to="/jobDescriptionApproval"
                              >
                                JD Approval
                              </Link>
                            </li>
                          )}

                          {(isSuperAdmin() ||
                            isUserHasPermission("Add_Template")) && (
                            <li className="nav-item mx-1">
                              <NavLink
                                className="nav-link fw-semibold text-black"
                                to="/allTemplates"
                              >
                                JD Templates
                              </NavLink>
                            </li>
                          )}
                        </>
                      </ul>
                    </div>
                  </>
                )}
                {isAuthenticated &&
                  (isSuperAdmin() ||
                    isUserHasPermission("Deals") ||
                    isUserHasPermission("Approve_Proposal_Request") ||
                    isUserHasPermission("Upload_Proposal_Request") ||
                    isUserHasPermission("Send_Bidding_Request") ||
                    isUserHasPermission("Send_Client_Proposal_Request")) && (
                    <div>
                      <div className="dropdown mx-2">
                        <div
                          className="nav-link fw-semibold dropdown-toggle "
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ cursor: "pointer" }}
                        >
                          Proposal
                        </div>
                        <ul className="dropdown-menu">
                          <>
                            {(isSuperAdmin() ||
                              isUserHasPermission("Deals")) && (
                              <>
                                <li className="nav-item  mx-1">
                                  <div
                                    className="nav-link fw-semibold text-black"
                                    aria-current="page"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      navigate("/deals");
                                    }}
                                  >
                                    Deals
                                  </div>
                                </li>
                              </>
                            )}
                            {(isSuperAdmin() ||
                              isUserHasPermission(
                                "Send_Client_Proposal_Request"
                              )) && (
                              <li className="nav-item  mx-1">
                                <div
                                  className="nav-link fw-semibold text-black"
                                  aria-current="page"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate("/proposallist", {
                                      state: "logged",
                                    });
                                  }}
                                >
                                  Sent Proposals
                                </div>
                              </li>
                            )}
                            {(isSuperAdmin() ||
                              isUserHasPermission(
                                "Upload_Proposal_Request"
                              )) && (
                              <li className="nav-item  mx-1">
                                <div
                                  className="nav-link fw-semibold text-black"
                                  aria-current="page"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate("/proposallist", {
                                      state: "assigned",
                                    });
                                  }}
                                >
                                  Proposal Requests
                                </div>
                              </li>
                            )}
                            {(isSuperAdmin() ||
                              isUserHasPermission(
                                "Approve_Proposal_Request"
                              )) && (
                              <li className="nav-item  mx1">
                                <div
                                  className="nav-link fw-semibold text-black"
                                  aria-current="page"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate("/proposallist", {
                                      state: "approval",
                                    });
                                  }}
                                >
                                  Approve Proposals
                                </div>
                              </li>
                            )}
                            {(isSuperAdmin() ||
                              isUserHasPermission("Send_Bidding_Request")) && (
                              <li className="nav-item  mx1">
                                <div
                                  className="nav-link fw-semibold text-black"
                                  aria-current="page"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate("/biddinglist", {
                                      state: "sent",
                                    });
                                  }}
                                >
                                  Biddings
                                </div>
                              </li>
                            )}
                            {(isSuperAdmin() ||
                              isUserHasPermission(
                                "Approve_Bidding_Request"
                              )) && (
                              <li className="nav-item  mx1">
                                <div
                                  className="nav-link fw-semibold text-black"
                                  aria-current="page"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    navigate("/biddinglist", {
                                      state: "approval",
                                    });
                                  }}
                                >
                                  Approve Biddings
                                </div>
                              </li>
                            )}
                          </>
                        </ul>
                      </div>
                    </div>
                  )}

                {isAuthenticated &&
                  (isSuperAdmin() ||
                    isUserHasPermission("All_Salary_Grade") ||
                    isUserHasPermission("Search_Salary_Grade") ||
                    isUserHasPermission("Accept_Reject_Salary_Grade") ||
                    isUserHasPermission("Add_NEW_Salary_Grade")) && (
                    <div className="dropdown mx-2">
                      <div
                        className="nav-link fw-semibold dropdown-toggle "
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ cursor: "pointer" }}
                      >
                        Salary Grade
                      </div>
                      <ul className="dropdown-menu">
                        {(isSuperAdmin() ||
                          isUserHasPermission("Add_NEW_Salary_Grade")) && (
                          <li className="nav-item  mx-1">
                            <Link
                              className="nav-link fw-semibold text-black"
                              aria-current="page"
                              to="/salaryGrade"
                            >
                              Add New
                            </Link>
                          </li>
                        )}
                        {(isSuperAdmin() ||
                          isUserHasPermission("Accept_Reject_Salary_Grade") ||
                          isUserHasPermission("All_Salary_Grade")) && (
                          <>
                            <li className="nav-item mx-1">
                              <Link
                                className="nav-link fw-semibold text-black"
                                aria-current="page"
                                to="/allSalaryGrade"
                              >
                                Approval
                              </Link>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  )}

                {isAuthenticated &&
                  (isSuperAdmin() ||
                    isUserHasPermission("Search_Salary_Grade")) && (
                    <li className="nav-item mx-2">
                      <NavLink
                        className="nav-link fw-semibold"
                        to="/searchSalaryGrade"
                      >
                        Search
                      </NavLink>
                    </li>
                  )}

                {isAuthenticated &&
                  (isSuperAdmin() || isUserHasPermission("Agents_List")) && (
                    <div className="dropdown mx-2">
                      <div
                        className="nav-link fw-semibold dropdown-toggle "
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ cursor: "pointer" }}
                      >
                        Administration
                      </div>

                      <ul className="dropdown-menu">
                        {(isSuperAdmin() ||
                          isUserHasPermission("Agents_List")) && (
                          <li className="nav-item mx-1">
                            <Link
                              className="nav-link fw-semibold text-black"
                              aria-current="page"
                              to="/agentList"
                            >
                              Agent
                            </Link>
                          </li>
                        )}
                        {isAuthenticated && isSuperAdmin() && (
                          <li className="nav-item mx-1">
                            <Link
                              className="nav-link fw-semibold text-black"
                              aria-current="page"
                              to="/roleList"
                            >
                              Role
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                {/* <div
                  className="nav-link fw-semibold"
                  onClick={() => {
                    switchLang();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {messages.buttons.langBtn}
                </div> */}

                <div className="dropdown-center mx-1">
                  <div
                    className="icon dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {userName
                      .split(" ")
                      .map((word) => word[0])
                      .join("")}
                  </div>
                  <ul className="dropdown-menu shadow-sm">
                    {/* <li>
                      <NavLink
                        to={"/profile"}
                        className=" nav-link fw-semibold "
                      >
                        profile
                      </NavLink>
                    </li> */}
                    <li>
                      <div
                        className="nav-link fw-semibold"
                        style={{ cursor: "pointer" }}
                        onClick={handleLogout}
                      >
                        Logout
                      </div>
                    </li>
                  </ul>
                </div>
              </>

              {!isAuthenticated && (
                <>
                  {" "}
                  <li className="nav-item mx-2">
                    <NavLink className="nav-link fw-semibold" to="/login">
                      Login
                    </NavLink>
                  </li>
                  {/* <li
                    className="nav-item mx-2"
                    onClick={() => {
                      switchLang();
                    }}
                  >
                    <div className="nav-link fw-semibold">{lang}</div>
                  </li> */}
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </Paper>
  );
}

export default injectIntl(Navbar);
