// import React from "react";
// import MainLayout from "./../../Pages/MainLayout/MainLayout";
// import { Navigate } from "react-router-dom";
// import { isSuperAdmin, isUserHasPermission } from "../../Utils/Permissions";
// import Navbar from "../Navbar/Navbar";

// export const MainLayoutComponent = (component) => {
//   return <MainLayout>{component}</MainLayout>;
// };
// export const NoLayout = (component) => {
//   return <>{component}</>;
// };
// // isSuperAdmin
// // employeeType
// export default function ProtectedRoutes({
//   component: Component,
//   permission,
//   path,
//   ...rest
// }) {
//   const isAuthenticated = localStorage.getItem("isAuthenticated")
//     ? true
//     : false;

//   const permissionArr =
//     permission && typeof permission !== "boolean"
//       ? permission.map((elt) => isUserHasPermission(elt))
//       : [];

//   return isAuthenticated ? (
//     // permissionArr.some((elt) => elt === true) ||
//     // isSuperAdmin() ||
//     localStorage.getItem("isSuperAdmin") ? (
//       // console.log(path)
//       <MainLayout>
//         <Navbar />
//         <Component />
//       </MainLayout>
//     ) : (
//       NoLayout(<Component />)
//     )
//   ) : (
//     NoLayout(<Navigate to="/login" />)
//   );
// }

import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "../Navbar/Navbar";

const ProtectedRoute = ({ component: Component, requiredRoles }) => {
  const { isAuthenticated,  } = useSelector((state) => state.auth);
  if (
    !isAuthenticated ||
    !requiredRoles.includes(localStorage.getItem("role"))
  ) {
    return <Navigate to="/login" />;
  }
  // console.log(requiredRoles)
  

  if (requiredRoles.includes(localStorage.getItem("role"))) {
    return (
      <>
        <Navbar />
        <Component />
      </>
    );
  }
};

export default ProtectedRoute;
